import React from 'react'
import { useIntl, Link } from "@intractive/gatsby-plugin-react-intl"
import Layout from '../../components/Layout'


const NotFoundPage = () => {
    const intl = useIntl();
  return (
  <Layout>
    <div className="row main-content">
      <div className="large-3 medium-3 columns sidebar hide-for-small"></div>
      <div className="large-9 medium-9 columns">
        <section>
          <h1>{intl.formatMessage({id: "Ai, Deze pagina bestaat niet"})}</h1>
          <div>
            <ul>
              <li><Link to="/">{intl.formatMessage({id: "Homepage"})}</Link></li>
              <li><Link to="/contact/">{intl.formatMessage({id: "Contact"})}</Link></li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)}

export default NotFoundPage
